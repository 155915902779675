<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div
            class="md-layout-item md-size-50"
          >
            <CoursesSelect
              v-model="filters.course_id"
            />
          </div>
          <div
            class="md-layout-item md-size-50"
          >
            <LevelsSelect
              v-model="filters.level_id"
            />
          </div>

          <div
            class="md-layout-item"
            :class="userIsAgentsManager ? 'md-size-33' : 'md-size-50'"
          >
            <ProgrammeStatusesSelect
              v-model="filters.status"
              multiple
            />
          </div>
          <div
            v-if="userIsAgentsManager"
            class="md-layout-item md-size-33"
          >
            <AgentsSelect
              v-model="filters.agent_id"
              :agent-groups="agentGroupsManagerIds"
            />
          </div>
          <div
            class="md-layout-item"
            :class="userIsAgentsManager ? 'md-size-33' : 'md-size-50'"
          >
            <VisaTypesSelect
              v-model="filters.visa_type"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.start_date_from"
              label="Start Date From"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.start_date_to"
              label="Start Date To"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.tuition_finish_date_from"
              label="Tuition Finish Date From"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.tuition_finish_date_to"
              label="Tuition Finish Date To"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getProgrammes"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import {
  AgentsSelect,
  ProgrammeStatusesSelect,
  VisaTypesSelect,
  CoursesSelect,
  LevelsSelect,
} from '@/components/Inputs/selects';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
    FormDate,
    AgentsSelect,
    ProgrammeStatusesSelect,
    VisaTypesSelect,
    CoursesSelect,
    LevelsSelect,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'programme_id',
            mask: 'Prog. ID',
            no_format: true,
            sortable: true,
          },
          {
            title: 'student.student_number',
            mask: 'Stud. No.',
            no_format: true,
            sortable: true,
          },
          {
            title: 'student.first_name',
            mask: 'given name',
            sortable: true,
          },
          {
            title: 'student.last_name',
            mask: 'surname',
            sortable: true,
          },
          {
            title: 'student.country.nationality',
            mask: 'nationality',
            sortable: true,
          },
          {
            title: 'agent.name',
            mask: 'agent',
            sortable: true,
            sort_value: 'agent.first_name',
          },
          {
            title: 'visa_type',
            mask: 'visa type',
            sortable: true,
            callback: (item) => this.parseVisaName(item),
          },
          {
            title: 'student.phone',
            mask: 'phone',
            sortable: true,
          },
          {
            title: 'course_weeks',
            mask: 'course weeks',
            sortable: true,
          },
          {
            title: 'holiday_weeks',
            mask: 'holiday weeks',
            sortable: true,
          },
          {
            title: 'total_weeks',
            mask: 'total weeks',
          },
          {
            title: 'start_date',
            mask: 'start date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'finish_date',
            mask: 'finish date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'expelled_date',
            mask: 'expelled date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'tuition_finish_date',
            mask: 'tuition finish date',
            sortable: true,
            dateFormat: true,
            strong: true,
          },
          {
            title: 'class_schedule.period',
            mask: 'Period',
            sortable: true,
          },
          {
            title: 'status',
            sortable: true,
          },
        ],
        actions: [],
        values: {},
      },
      filters: {
        status: null,
        start_date_from: null,
        start_date_to: null,
        tuition_finish_date_from: null,
        tuition_finish_date_to: null,
        visa_type: null,
        agent_id: null,
      },
      loading: false,
      params: {},
    };
  },
  computed: {
    tableHasContent() {
      return this.vTable.values.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getProgrammes(params) {
      this.params = { ...this.filters, ...params };

      this.loading = true;

      this.request('programmes_report', 'get', { ...this.params }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.total_weeks = x.course_weeks + x.holiday_weeks;
          return x;
        });
      }, () => {
        this.loading = false;
      });
    },
    onFilter() {
      this.$refs.vtable.init();

      this.params = { ...this.filters, ...this.params };
    },
    onDownload() {
      this.loading = true;
      this.request('programmes_report', 'download', { ...this.params, download: true }, ({ data }) => {
        const filename = 'Programmes_report.xlsx';
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>
